import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from 'react-bootstrap'
import VideoPlaceholder from "../images/video-placeholder.jpg"
import Layout from '../layouts/layout'
import { Helmet } from 'react-helmet'

interface TextRow {
    title: String,
    description: { description: string }
}

const PrivacyPage = () => {
    const data = useStaticQuery(graphql`
        query PrivacyQuery {
            contentfulLayout(contentful_id: {eq: "de8u3c7ysDNtREPl6gSRM"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                            description
                        }
                    }
                }
                hero {
                    header_title
                }
            }
        }
    `)

    const page = data.contentfulLayout

    const textRows: TextRow[] = page.contentModule

    return(
        <Layout>
            <Helmet>
                <title>O3 Edge | Privacy</title>
            </Helmet>

            <div className="hero footer-page" style={{ backgroundImage: `url(${VideoPlaceholder})`}}>
                <Container>
                    <Row>
                        <Col className="py-5 text-center">
                            <h1>{page.hero.header_title}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                {textRows.map((row, index) =>
                    <Row key={`tos-row-${index}`} className="py-5">
                        <Col>
                            <h2 className="pb-3 text-center">{row.title}</h2>
                            <ReactMarkdown>{row.description.description}</ReactMarkdown>
                        </Col>
                    </Row>
                    )}
            </Container>
        </Layout>
    )
}

export default PrivacyPage